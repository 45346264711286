<template>
  <q-layout view="hHh LpR fFf">
    <q-btn
      dense
      flat
      round
      icon="menu"
      @click="leftShown = !leftShown"
      style="float: right; margin-right: 15px; margin-top: 15px"
    />
    <menu-drawer
      v-model="leftShown"
      :mini.sync="menuMini"
      :auto-expand="menuAutoExpand"
    >
      <template v-slot:menu>
        <menu-list
          :flist.sync="menuMini"
          :menu-items="menuItems"
          v-on:expansionItemClick="menuMini = false"
        ></menu-list>
      </template>
    </menu-drawer>

    <q-page-container>
      <q-banner
        v-if="parserStatus"
        dense
        inline-actions
        class="text-white text-body1 bg-red"
        style="text-align: center;font-weight: bold;font-size: 22px"
      >
      </q-banner>
      <div>
        <label class="bvm_h6" style="padding: 15px;display: block"
          >{{ $t("menu")[$route.name] }}
          <q-select
            filled
            size="sm"
            v-model="mainGroup"
            use-input
            input-debounce="0"
            label="Выбранная группа"
            :options="options"
            option-value="tg"
            option-label="name"
            @filter="filterFn"
            style="width: 400px;float: right;font-size: 10px"
            behavior="dialog"
          >
            <template v-slot:no-option>
              <q-item>
                <q-item-section class="text-grey">
                  No results
                </q-item-section>
              </q-item>
            </template>
          </q-select>
        </label>
      </div>
      <a
        @click="prevPages"
        style="padding: 15px;cursor: pointer;display: block;width: max-content;"
        >назад</a
      >
      <main
        class="q-page ub-page"
        :style="!menuMini ? 'padding: 0 40px 0 0' : 'padding: 0 40px 0 40px'"
      >
        <div class="ub-page">
          <div class="q-gutter-y-md" style="max-width: 100%;">
            <q-card
              style="background-color: white;width: 90%; text-align: center;display: inline;margin-bottom: 15px"
            >
              <q-tabs v-model="setType" v-if="callsType.length">
                <q-tab
                  v-for="r in callsType"
                  :name="r.id"
                  :label="r.name"
                  :key="r.id + Math.random()"
                  dense
                />
              </q-tabs>
              <q-tabs v-model="setMonth" v-if="setType">
                <q-tab
                  v-for="(r, ind) in $months"
                  :name="ind"
                  :label="r"
                  :key="ind"
                  dense
                  ><q-badge :label="fcount[setType][ind]" floating transparent
                /></q-tab>
              </q-tabs>
            </q-card>
          </div>
        </div>
      </main>
      <transition :name="transitionName">
        <router-view
          :style="!menuMini ? 'padding: 0 40px 0 0' : 'padding: 0 40px 0 40px'"
        />
      </transition>
    </q-page-container>
    <q-inner-loading
      :showing="$store.state.progress"
      label="Please wait..."
      label-class="text-teal"
      label-style="font-size: 1.1em"
    >
      <q-spinner-gears size="50px" :color="$store.state.color" />
      <label
        class="dinamicAlertText"
        style="
          padding: 15px;
          font-size: 16px;
          max-width: 400px;
          text-align: center;
        "
        >{{ $t("pleaseWait")[cNewMessage] }}
      </label>
    </q-inner-loading>
  </q-layout>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { dbColors } from "dashblocks";
import MenuDrawer from "../components/menu/menudrawer.vue";
import MenuList from "../components/menu/menulist.vue";
import settings from "@/pages/settings.vue";

export default {
  name: "MainLayout",
  components: {
    MenuDrawer,
    MenuList
  },
  data() {
    return {
      setMonth: new Date().getMonth(),
      setType: null,
      callsType: [],
      cNewMessage: 0,
      leftShown: true,
      rightShown: false,
      settingsOpen: false,
      messagesOpen: false,
      userInfoOpen: false,
      showSearchToolbar: false,
      searchQuery: "",
      transitionName: "",
      telegsName: "",
      testColors: null,
      menuItems: [],
      prevPage: "/",
      statusText: "",
      parserStatus: false,
      refreshIntervalId: null,
      groups: [],
      fcount: [],
      options: [],
      mainGroup: {}
    };
  },
  computed: {
    ...mapState({
      menuAutoExpand: state => state.layout.menuAutoExpand
    }),
    dark: {
      get() {
        return this.$store.state.layout.dark;
      },
      set(value) {
        this.setDark({ dark: value });
      }
    },
    menuMini: {
      get() {
        return this.$store.state.layout.menuMini;
      },
      set(value) {
        this.setMenuMini({ menuMini: value });
      }
    },
    smallScreen() {
      return ["xs", "sm"].includes(this.$q.screen.name);
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.prevRoute = from;
    });
  },
  watch: {
    setMonth(val) {
      this.$store.state.setMonth = val + 1;
    },
    setType(val) {
      this.$store.state.setType = val;
    },
    "$store.state.setType"(val) {
      this.setType = val;
    },
    mainGroup(val, old) {
      if (val != null) {
        this.setGroup();
      }
    },
    dark: {
      handler: function(val) {
        this.$q.dark.set(val);
      }
    },
    "$route.fullPath"(val) {
      if (val == "/logout") {
        this.logout();
      }
      this.$store.state.subTitle = null;
      this.$history.push(val);
      if (this.$history.length > 1) {
        this.prevPage = this.$history[this.$history.length - 2];
      } else {
        this.prevPage = "/";
      }
    },
    "$store.state.progress"(val) {
      if (val) {
        this.sendAlertText();
      }
    }
  },
  created() {
    this.getGroups();
  },
  mounted() {
    this.makeMenu();
    this.initialize();
    this.$q.dark.set(this.dark);
  },
  methods: {
    ...mapActions({
      setDark: "layout/setDark",
      setMenuMini: "layout/setMenuMini"
    }),
    filterFn(val, update) {
      if (val === "") {
        update(() => {
          this.options = this.groups;
        });
        return;
      }
      update(() => {
        const needle = val.toLowerCase();
        this.$h
          .get(`/analitics/get-groups-name?name=${val.toLowerCase()}`)
          .then(r => {
            this.options = r.data.data.gp;
          });
      });
    },
    getGroups(name = null) {
      this.$store.state.progress = true;
      this.$h
        .get("/analitics/get-groups-name?" + (name ? `name=${name}` : ""))
        .then(r => {
          this.groups = r.data.data.gp;
          this.mainGroup = r.data.data.tg;
          this.$store.state.progress = false;
        });
    },
    setGroup() {
      this.$h
        .post("/analitics/set-group", { gp: this.mainGroup.id })
        .then(r => {
          if (r.data.data) {
            this.$router.push("/");
            location.reload();
          }
        });
    },
    logout: function() {
      this.$store.dispatch("logout").then(() => {
        this.$router.push("/login");
      });
    },
    prevPages() {
      this.$history.splice(this.$history.length - 2, 1);
      if (this.$route.fullPath == this.prevPage) {
        this.$router.push("/");
      }
      this.$router.push(this.prevPage);
    },
    sendAlertText() {
      let l = 0;
      let allerArr = this.$t("pleaseWait");
      setInterval(() => {
        if (l < allerArr.length) {
          // alert[0].innerText = allerArr[l];
          l++;
          this.cNewMessage = l;
        }
      }, 3000);
    },
    makeMenu() {
      let user = JSON.parse(localStorage.getItem("user"));
      this.telegsName = user.telega;
      this.menuItems.push({
        id: "main_page",
        title: this.$t("menu").main_page,
        link: "/",
        icon: "mdi-settings-helper",
        level: 0
      });
      this.menuItems.push({
        id: "callmanagers",
        title: this.$t("menu").callmanagers,
        link: "/call-managers",
        icon: "mdi-card-account-phone-outline",
        level: 0
      });
      this.menuItems.push({
        id: "gp_setting",
        title: this.$t("menu").gp_setting,
        link: "/group-setting",
        icon: "mdi-cog",
        level: 0
      });
      this.$h
        .get(`/groups/get-int`)
        .then(r => {
          let crm = r.data.data.crms;
          this.fcount = r.data.data.f;
          for (let i in crm) {
            this.callsType.push({ id: crm[i].crm, name: crm[i].name });
            this.menuItems.splice(2, 0, {
              id: `analytics_${crm[i].crm}`,
              title: this.$t("menu").analytics + " " + crm[i].name,
              link: `/analytics/${crm[i].crm}`,
              icon: "mdi-chart-areaspline",
              level: 0
            });
            this.menuItems.splice(this.menuItems.length, 0, {
              id: crm[i].crm,
              title: "Настройка " + crm[i].name,
              link: `/crm/${crm[i].crm}`,
              level: 0,
              icon: "mdi-cog"
            });
          }
          if (this.callsType.length && this.setType == null) {
            this.setType = this.callsType[0].id;
          }
          let tels = r.data.data.tels;
          for (let i in tels) {
            this.callsType.push({ id: tels[i].tel, name: tels[i].name });
            this.menuItems.splice(2, 0, {
              id: `analytics_${tels[i].tel}`,
              title: this.$t("menu").analytics + " " + tels[i].name,
              link: `/analytics/${tels[i].tel}`,
              icon: "mdi-chart-areaspline",
              level: 0
            });
            this.menuItems.splice(this.menuItems.length, 0, {
              id: tels[i].tel,
              title: tels[i].name,
              link: `/telefony/${tels[i].tel}`,
              level: 0,
              icon: "mdi-cog"
            });
          }
          if (this.callsType.length && this.setType == null) {
            this.setType = this.callsType[0].id;
          }
          let disk = r.data.data.disk;
          for (let i in disk) {
            this.callsType.push({
              id: disk[i].disk,
              name: "Яндекс диск - " + disk[i].name
            });
            this.menuItems.splice(2, 0, {
              id: `analytics_${disk[i].disk}`,
              title: this.$t("menu").analytics + " Яндекс диск " + disk[i].name,
              link: `/analytics/${disk[i].disk}`,
              icon: "mdi-chart-areaspline",
              level: 0
            });
            this.menuItems.splice(this.menuItems.length, 0, {
              id: disk[i].disk,
              title: "Яндекс диск - " + disk[i].name + "",
              link: `/disk/${disk[i].disk}`,
              level: 0,
              icon: "mdi-cog"
            });
          }
          if (this.callsType.length && this.setType == null) {
            this.setType = this.callsType[0].id;
          }
        })
        .then(() => {
          this.menuItems.push({
            id: "billing",
            title: this.$t("menu").billing,
            link: "/billing",
            icon: "mdi-cash-multiple",
            level: 0
          });
          this.menuItems.splice(this.menuItems.length, 0, {
            id: "logout",
            title: this.$t("menu").logout,
            link: "/logout",
            icon: "mdi-exit-run",
            level: 0
          });
        });
      if (user.role == "admin") {
        this.menuItems.push({
          id: "bot_group",
          title: this.$t("menu").settings,
          link: "/settings",
          icon: "mdi-cog",
          level: 0
        });
        this.menuItems.push({
          id: "templates",
          title: this.$t("menu").templates,
          link: "/templates",
          icon: "mdi-application",
          level: 0
        });
        this.menuItems.push({
          id: "users",
          title: this.$t("menu").users,
          link: "/users",
          icon: "mdi-account-settings",
          level: 0
        });
        this.menuItems.push({
          id: "billing_groups",
          title: this.$t("menu").billing_groups,
          link: "/billing-groups",
          icon: "mdi-cash-multiple",
          level: 0
        });
      }
    },
    initialize() {
      let dbc = dbColors;

      //this.testColors = dbColors.getColors(true); // TEMP TODO REMOVE
      let cSteps = [0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1];

      dbColors.setColorScheme("default", {
        light: dbColors.d3ScaleChromatic.schemeTableau10,
        dark: dbColors.grafanaColors
      });

      dbColors.setColorScheme("Grafana", {
        light: dbColors.grafanaColors,
        dark: dbColors.grafanaColors
      });

      dbColors.setColorScheme("Tableau", {
        light: dbColors.d3ScaleChromatic.schemeTableau10,
        dark: dbColors.d3ScaleChromatic.schemeTableau10
      });

      dbColors.setColorScheme("Diverging", {
        light: dbColors.d3ScaleChromatic.schemeRdYlBu[10],
        dark: dbColors.d3ScaleChromatic.schemeRdYlBu[10]
      });

      dbColors.setColorScheme("Categorical", {
        light: dbColors.d3ScaleChromatic.schemeDark2,
        dark: dbColors.d3ScaleChromatic.schemeSet3 // schemeBuGn[9],
      });

      dbColors.setColorScheme("Warm", {
        light: cSteps.map(x => dbColors.d3ScaleChromatic.interpolateWarm(x)),
        dark: cSteps.map(x => dbColors.d3ScaleChromatic.interpolateWarm(x))
      });

      dbColors.setColorScheme("Cool", {
        light: cSteps.map(x => dbColors.d3ScaleChromatic.interpolateCool(x)),
        dark: cSteps.map(x => dbColors.d3ScaleChromatic.interpolateCool(x))
      });

      dbColors.setColorScheme("Calm", {
        light: [
          "#912e4d",
          "#00bd56",
          "#f02192",
          "#acd36d",
          "#8079ff",
          "#919200",
          "#f1adff",
          "#547600",
          "#ff8241",
          "#f8ba7a"
        ],
        dark: [
          "#ce4c3a",
          "#60b14d",
          "#8162cb",
          "#bab141",
          "#c964b5",
          "#4bb092",
          "#c25874",
          "#717e37",
          "#688ccd",
          "#c78344"
        ]
      });
      //['#cc4ba2', '#64ac48', '#8361cd', '#9a963f', '#5f8cce', '#cd5136', '#4aac8d', '#c7596d', '#c78543', '#b578b6']
      //['#ce4c3a', '#60b14d', '#8162cb', '#bab141', '#c964b5', '#4bb092', '#c25874', '#717e37', '#688ccd', '#c78344']

      dbColors.setColorScheme("Fancy", {
        light: [
          "#38646f",
          "#4e2300",
          "#274f8e",
          "#6b5e1f",
          "#794f81",
          "#2a2e00",
          "#00485e",
          "#7c553f",
          "#2e0a06",
          "#2b2219"
        ],
        dark: [
          "#b1d8a0",
          "#74aff3",
          "#dbcd9d",
          "#7bcaed",
          "#ebaba7",
          "#74d6e0",
          "#deb1e0",
          "#a1e9d1",
          "#adbce9",
          "#8dc4af"
        ]
      });

      dbColors.setColorScheme("Colorblind Friendly", {
        light: [
          "#37efab",
          "#58006c",
          "#b3e370",
          "#9a73ec",
          "#b1a200",
          "#0051ab",
          "#ff9e6a",
          "#601016",
          "#685d00",
          "#de3357"
        ],
        dark: [
          "#78a563",
          "#666fe8",
          "#c1b01b",
          "#014ca6",
          "#ffca5e",
          "#e2b1ff",
          "#008418",
          "#ff77bf",
          "#811e00",
          "#ff8c56"
        ]
      });
    }
  }
};
</script>
<style lang="scss">
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */
 {
  opacity: 0;
}
</style>
